<template>
  <div class="lg:flex lg:space-x-10">
    <div class="lg:w-3/5 space-y-7 mb-8">
      <form
        class="lg:p-10 p-6 space-y-3 relative bg-white shadow-xl rounded-md"
        v-if="!hasPermission"
      >
        <p class="font-semibold mb-6">
          Bạn chưa là thành viên của chủ đề này. Liên hệ với người tạo chủ đề để
          tham gia.
        </p>
      </form>
      <div v-else>
        <div class="card p-4">
          <h1 class="lg:text-3xl text-2xl font-semibold mb-6">
            {{ discussion.title }}
          </h1>
          <div class="breadcrumb-area py-0">
            <div class="breadcrumb">
              <ul class="m-0">
                <li>
                  <router-link title="Diễn đàn" :to="{ name: 'ForumIndex' }">
                    Diễn đàn
                  </router-link>
                </li>
                <li>
                  <router-link
                    v-if="discussion.forum && discussion.forum.parent"
                    :title="discussion.forum.parent.title"
                    :to="{
                      name: 'ForumDetail',
                      params: {
                        id: discussion.forum.parent.id,
                        slug: discussion.forum.parent.slug,
                      },
                    }"
                  >
                    {{ discussion.forum.parent.title }}
                  </router-link>
                </li>
                <li class="active">
                  <router-link
                    v-if="discussion.forum"
                    :title="discussion.forum.title"
                    :to="{
                      name: 'ForumDetail',
                      params: {
                        id: discussion.forum.id,
                        slug: discussion.forum.slug,
                      },
                    }"
                  >
                    {{ discussion.forum.title }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="flex items-center justify-content-between space-x-3 my-5 pb-4 border-b"
          >
            <div class="flex space-x-3">
              <router-link
                v-if="discussion.author"
                :to="{
                  name: 'UserDetail',
                  params: { id: discussion.author.id },
                }"
              >
                <img
                  :alt="discussion.author.username"
                  :src="
                    discussion.author.avatar
                      ? discussion.author.avatar.url
                      : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'
                  "
                  class="w-10 h-10 rounded-full object-cover"
                />
              </router-link>
              <img
                v-if="!discussion.author"
                src="https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg"
                alt=""
                class="w-10 h-10 rounded-full object-cover"
              />
              <div>
                <div class="text-base font-semibold">
                  <router-link
                    v-if="discussion.author"
                    :to="{
                      name: 'UserDetail',
                      params: { id: discussion.author.id },
                    }"
                  >
                    {{ discussion.author.username }}
                  </router-link>
                  <span v-if="!discussion.author">{{
                    discussion.author_name
                  }}</span>
                </div>
                <div class="text-xs">{{ timeago(discussion.created_at) }}</div>
              </div>
            </div>

            <div v-if="isAuthor || isAdmin">
              <a href="#">
                <i
                  class="icon-feather-more-horizontal text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"
                ></i>
              </a>
              <div
                class="bg-white w-62 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700"
                uk-drop="mode: click;pos: bottom-right;animation: uk-animation-slide-bottom-small"
              >
                <ul class="space-y-1">
                  <li>
                    <a
                      class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                      href="#"
                      uk-toggle="target: #add-poll-modal"
                      ><i class="uil-plus mr-1"></i> Tạo cuộc thăm dò</a
                    >
                  </li>
                  <li>
                    <router-link
                      :to="{ name: 'DiscussionEdit', params: { id: id } }"
                      class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                    >
                      <i class="uil-pen mr-1"></i> Sửa
                    </router-link>
                  </li>
                  <li>
                    <a
                      class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                      href="#"
                      :uk-toggle="
                        'target: #delete-discussion-modal' + discussion.id
                      "
                      ><i class="uil uil-trash mr-1"></i> Xóa</a
                    >
                  </li>
                </ul>
              </div>
            </div>

            <a
              href="#"
              uk-toggle="target: #addMemberModal"
              v-if="discussion.is_private === 1"
              class="flex items-center justify-center px-3 py-2 rounded-md bg-green-600 hover:bg-green-700 hover:text-white text-white space-x-1.5"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="w-5"
              >
                <path
                  d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
                ></path>
              </svg>
              <span class="whitespace-nowrap"
                >Thành viên ({{ discussion.users.paginatorInfo.total }})</span
              >
            </a>
          </div>
          <div
            v-if="
              discussion.polls &&
              discussion.polls.data &&
              discussion.polls.data.length
            "
          >
            <poll-compact
              v-on:pollChangeDone="loadDiscussion"
              :poll="poll"
              :isAuthor="isAuthor"
              v-for="poll in discussion.polls.data"
              :key="'poll' + poll.id"
            />
          </div>
          <div
            class="space-y-3 content"
            uk-lightbox="toggle:.lightbox"
            v-html="discussion.content"
          ></div>
          <div v-if="discussion.song" class="mt-5">
            <span
              class="px-2 py-1 text-xs rounded-full bg-green-500 text-white whitespace-nowrap"
              >Đã đăng</span
            >
            Link:
            <router-link
              :to="{
                name: 'SongDetail',
                params: {
                  id: discussion.song.id,
                  slug: discussion.song.slug,
                  prefix: getPrefixFromSongType(discussion.song['__typename']),
                },
              }"
              class="font-semibold"
              :title="discussion.song.title"
            >
              {{ discussion.song.title }}
            </router-link>
            <span class="text-dot text-xs">{{
              timeago(discussion.song.created_at)
            }}</span>
          </div>
          <div
            class="mt-5 justify-content-center flex"
            v-if="
              canEditSong &&
              !discussion.song &&
              (discussion.content.indexOf('.mp3') > -1 ||
                discussion.content.indexOf('.mp4') > -1)
            "
          >
            <a
              href="#"
              class="comment-btn mt-2 hover:bg-green-700"
              uk-toggle="target: #approveTopicToSongModal"
            >
              <i class="fe icon-feather-upload"></i> Đăng bài hát
            </a>
          </div>
        </div>

        <div class="mt-5">
          <comment
            :allow-comment="discussion.is_closed !== 1"
            object-type="discussion"
            :comment-id="commentId"
            :show-more="false"
            :object-id="id"
            field="id"
            type="ID"
          />
        </div>

        <div id="addMemberModal" class="create-post is-story" uk-modal>
          <div
            class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small"
          >
            <div class="text-center py-3 border-b">
              <h3 class="text-lg font-semibold">
                Thành viên tham gia ({{ discussion.users.paginatorInfo.total }})
              </h3>
              <button
                class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
                type="button"
                uk-close
                uk-tooltip="title: Close ; pos: bottom ;offset:7"
              ></button>
            </div>

            <div class="px-5 py-2" v-if="isAuthor">
              <div class="col-span-2">
                <label class="font-semibold">Thêm thành viên</label>
                <multiselect
                  v-model="users"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchUsers"
                >
                  <template v-slot:option="{ option }">
                    <img
                      class="w-10 h-10 rounded-full object-cover mr-3"
                      :src="
                        option.avatar
                          ? option.avatar.url
                          : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'
                      "
                    />
                    {{ option.username }}
                  </template>

                  <template v-slot:tag="{ option, handleTagRemove, disabled }">
                    <div class="multiselect-tag is-user">
                      <img
                        :src="
                          option.avatar
                            ? option.avatar.url
                            : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'
                        "
                        class="w-6 h-6 rounded-full object-cover mr-3"
                      />
                      {{ option.username }}
                      <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                      >
                        <span class="multiselect-tag-remove-icon"></span>
                      </span>
                    </div>
                  </template>
                </multiselect>
              </div>

              <div class="flex w-full border-t p-3 pl-0">
                <a
                  href="javascript:void(0)"
                  v-on:click="addBatchUserDiscussion"
                  class="bg-green-600 hover:bg-green-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold"
                >
                  Thêm thành viên</a
                >
              </div>
            </div>

            <div data-simplebar style="height: 250px">
              <div class="px-5">
                <div
                  v-if="
                    discussion.users &&
                    discussion.users.data &&
                    discussion.users.data.length
                  "
                >
                  <div
                    v-for="(user, index) in discussion.users.data"
                    :key="'user' + index"
                    class="flex items-center space-x-4 rounded-md -mx-2 p-2 hover:bg-gray-50"
                  >
                    <router-link
                      :to="{ name: 'UserDetail', params: { id: user.id } }"
                      class="w-12 h-12 flex-shrink-0 overflow-hidden rounded-full relative"
                    >
                      <img
                        :src="
                          user.avatar
                            ? user.avatar.url
                            : 'https://static.bcdcnt.net/assets/images/avatars/avatar-1.jpg'
                        "
                        class="absolute w-full h-full inset-0"
                        alt=""
                      />
                    </router-link>
                    <div class="flex-1">
                      <router-link
                        :to="{ name: 'UserDetail', params: { id: user.id } }"
                        class="w-12 h-12 flex-shrink-0 overflow-hidden rounded-full relative"
                      >
                        <span class="text-base font-semibold capitalize">{{
                          user.username
                        }}</span>
                      </router-link>
                    </div>
                    <a
                      v-if="isAuthor"
                      v-on:click="removeUserDiscussion(user.id)"
                      href="javascript:void(0)"
                      class="flex items-center justify-center h-8 px-3 rounded-md text-sm border font-semibold"
                    >
                      Xóa
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lg:w-2/5 w-full space-y-7">
      <div class="card p-3">
        <latest-comment />
      </div>
      <right />
    </div>

    <add-poll
      v-on:pollChangeDone="loadDiscussion"
      v-if="discussion.id"
      :pollable_id="discussion.id"
      pollable_type="discussion"
    />

    <edit-poll v-on:pollChangeDone="handlePollChangeDone" :pollProp="poll" />

    <div
      v-if="discussion.id"
      :id="'delete-discussion-modal' + discussion.id"
      class="create-post is-story"
      uk-modal
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small"
      >
        <div class="text-center py-3 border-b">
          <h3 class="text-lg font-semibold">Xóa chủ đề</h3>
          <button
            :id="'delete-discussion-modal' + discussion.id + 'Close'"
            class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
            type="button"
            uk-close
            uk-tooltip="title: Close ; pos: bottom ;offset:7"
          ></button>
        </div>

        <div class="py-4 px-4">
          Bạn có chắc muốn xóa chủ đề <strong>{{ discussion.title }}?</strong>
        </div>

        <div class="flex items-center w-full justify-center border-t p-3">
          <a
            href="javascript:void(0)"
            v-on:click="deleteDiscussion"
            class="bg-red-600 hover:bg-red-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold"
          >
            Xóa chủ đề
          </a>
        </div>
      </div>
    </div>

    <div id="approveTopicToSongModal" class="create-post" uk-modal>
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small"
      >
        <div class="text-center py-3 border-b">
          <h3 class="text-lg font-semibold">Đăng bài hát/thay thế bản thu</h3>
          <button
            class="approveTopicToSongClose uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
            type="button"
            uk-close
            uk-tooltip="title: Close ; pos: bottom ;offset:7"
          ></button>
        </div>

        <div class="px-5 py-2">
          <div>
            <label class="font-semibold">Thể loại đăng</label>
            <div class="flex">
              <span class="flex items-center mr-4"
                ><label style="margin-bottom: 0" for="approveTypeSong"
                  >Bài hát</label
                ><input
                  id="approveTypeSong"
                  class="ml-1"
                  type="radio"
                  v-model="approveType"
                  v-bind:value="'song'"
              /></span>
              <span class="flex items-center mr-4"
                ><label style="margin-bottom: 0" for="approveTypeFolk"
                  >Dân ca</label
                ><input
                  id="approveTypeFolk"
                  class="ml-1"
                  type="radio"
                  v-model="approveType"
                  v-bind:value="'folk'"
              /></span>
              <span class="flex items-center mr-4"
                ><label style="margin-bottom: 0" for="approveTypeInstrumental"
                  >Khí nhạc</label
                ><input
                  id="approveTypeInstrumental"
                  class="ml-1"
                  type="radio"
                  v-model="approveType"
                  v-bind:value="'instrumental'"
              /></span>
              <span class="flex items-center mr-4"
                ><label style="margin-bottom: 0" for="approveTypePoem"
                  >Tiếng thơ</label
                ><input
                  id="approveTypePoem"
                  class="ml-1"
                  type="radio"
                  v-model="approveType"
                  v-bind:value="'poem'"
              /></span>
              <span class="flex items-center"
                ><label style="margin-bottom: 0" for="approveTypeReplace"
                  >Thay thế</label
                ><input
                  id="approveTypeReplace"
                  class="ml-1"
                  type="radio"
                  v-model="approveType"
                  v-bind:value="'replace'"
              /></span>
            </div>
          </div>

          <div class="mt-3" v-if="approveType === 'replace'">
            <label for="approveLink" class="font-semibold">Link thay thế</label>
            <input
              v-model="approveLink"
              class="shadow-none with-border"
              id="approveLink"
              type="text"
              autocomplete="off"
              required
            />
          </div>
        </div>

        <div class="flex items-center w-full justify-center border-t p-3">
          <a
            href="javascript:void(0)"
            v-on:click="approveTopicToSong"
            class="bg-green-600 hover:bg-green-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold"
          >
          Đăng bài hát</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
input.multiselect-tags-search {
  height: auto !important;
}
</style>

<script>
import ApiService from "../../core/services/api.service";
import {
  canEditSong,
  censorBadWords,
  closeModal,
  convertOembed,
  getPrefixFromSongType,
  isAdmin,
  lightBoxable,
  logActivity,
  makePlayTime,
  timeago,
} from "../../core/services/utils.service";
import Comment from "../comment/Comment";
import { getUser } from "../../core/services/jwt.service";
import Multiselect from "@vueform/multiselect";
import LatestComment from "../comment/LatestComment";
import Right from "../right/Right";
import AddPoll from "../poll/AddPoll";
import PollCompact from "../poll/PollCompact";

export default {
  name: "DiscussionDetail",
  components: {
    Comment,
    Multiselect,
    LatestComment,
    Right,
    AddPoll,
    PollCompact,
  },
  data() {
    return {
      id: 0,
      commentId: null,
      hasPermission: true,
      discussion: {
        title: "",
        content: "",
        author_name: "",
        is_closed: 0,
        created_at: "",
        is_private: 0,
        users: {
          data: [],
          paginatorInfo: {
            total: 0,
          },
        },
      },
      users: [],
      approveType: "song",
      approveLink: "",
    };
  },
  methods: {
    loadDiscussion() {
      let query = `query($id: ID!) {
        discussion(id: $id) {
          id
          title
          slug
          content
          author_name
          created_at
          status
          is_closed
          is_private
          author {
            id
            username
            avatar {
              url
            }
          }
          thumbnail {
            url
          }
          forum {
            id
            title
            slug
            parent {
              id
              title
              slug
            }
          }
          users(first: 100) {
            data {
              id
              username
              avatar {
                url
              }
            }
            paginatorInfo {
              total
            }
          }
          song {
            __typename
            ... on Song {
                  id
                  title
                  slug
                  created_at
                }
          }
          polls(first: 5) {
            data {
              id
              title
              status
              created_at
              updated_at
              user {
                id
                username
                avatar {
                  url
                }
              }
              options(orderBy: [{column: "position", order: ASC}]) {
                id
                name
                total_answer
                percent
                position
                answers(first: 5, orderBy: [{column: "id", order: DESC}]) {
                  data {
                    user {
                      id
                      username
                      avatar {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      `;

      ApiService.graphql(query, { id: this.id })
        .then(({ data }) => {
          if (data.data && data.data.discussion) {
            if (data.data.discussion.status !== 1) {
              this.$router.push({ name: "404", params: { catchAll: "404" } });
              return;
            }

            this.discussion = data.data.discussion;
            this.discussion.title = censorBadWords(this.discussion.title);
            this.discussion.content = lightBoxable(
              convertOembed(
                makePlayTime(censorBadWords(this.discussion.content))
              )
            );
          } else {
            if (data.errors[0]) {
              if (data.errors[0].message === "Unauthenticated.") {
                this.$router.push({
                  name: "Login",
                  query: { next: window.location },
                });
                this.$toast.error("Bạn cần đăng nhập để xem nội dung này");
              } else if (
                data.errors[0].message === "This action is unauthorized."
              ) {
                this.hasPermission = false;
              }
            }
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async searchUsers(q) {
      let query = `query($q: Mixed) {
        users(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "username", operator: LIKE, value: $q}]}) {
          data {
            id
            username
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, { q: `${q}%` })
        .then(({ data }) => {
          if (data.data && data.data.users && data.data.users.data) {
            return data.data.users.data;
          } else {
            return [];
          }
        })
        .catch((response) => {
          console.log(response);
          return [];
        });
    },
    addBatchUserDiscussion() {
      let query = `mutation($user_ids: [ID]!, $discussion_id: ID!) {
        addBatchUserDiscussion(
          user_ids: $user_ids,
          discussion_id: $discussion_id
        )
      }`;

      let user_ids = [];
      this.users.map((item) => {
        user_ids.push(item.id);
      });

      ApiService.graphql(query, {
        user_ids: user_ids,
        discussion_id: this.discussion.id,
      })
        .then(({ data }) => {
          if (data.data && data.data.addBatchUserDiscussion) {
            this.$toast.success("Thêm thành viên thành công");
            this.users = [];
            this.loadDiscussion();
          } else {
            this.$toast.error(data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
    removeUserDiscussion(user_id) {
      let query = `mutation($user_id: ID!, $discussion_id: ID!) {
        removeUserDiscussion(
          user_id: $user_id,
          discussion_id: $discussion_id
        )
      }`;

      ApiService.graphql(query, {
        user_id: user_id,
        discussion_id: this.discussion.id,
      })
        .then(({ data }) => {
          if (data.data && data.data.removeUserDiscussion) {
            this.$toast.success("Xóa thành viên thành công");
            this.loadDiscussion();
          } else {
            this.$toast.error(data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
    approveTopicToSong() {
      let query = `mutation($discussion_id: ID!, $type: String, $link: String) {
          approveTopicToSong(discussion_id: $discussion_id, type: $type, link: $link) {
            id
          }
        }`;

      ApiService.graphql(query, { discussion_id: this.discussion.id, type: this.approveType, link: this.approveLink })
        .then(({ data }) => {
          if (data.data && data.data.approveTopicToSong) {
            this.$toast.success("Đăng bài hát thành công");
            this.$router.push({ name: "Home" });
            closeModal("approveTopicToSong");
          } else {
            this.$toast.error(data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
    deleteDiscussion() {
      let query = `mutation($id: ID!) {
        deleteDiscussion(id: $id) {
          id
        }
      }`;

      ApiService.graphql(query, { id: this.discussion.id })
        .then(({ data }) => {
          if (data.data && data.data.deleteDiscussion) {
            this.$toast.success("Xóa chủ đề thành công");
            this.$router.push({
              name: "Home",
            });
            closeModal("delete-discussion-modal" + this.discussion.id);
          } else {
            this.$toast.error(data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
    reRender() {
      this.loadDiscussion();
      logActivity("view", "discussion", this.id);
    },
    timeago(x) {
      return timeago(x);
    },
    getPrefixFromSongType(type) {
      type = type.toLowerCase();
      return getPrefixFromSongType(type);
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.commentId = this.$route.query.comment_id;
    this.reRender();
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    this.commentId = this.$route.query.comment_id;
    this.reRender();
    window.scrollTo(0, 0);
    next();
  },
  computed: {
    isAuthor() {
      let user = getUser();

      if (user && user.profile_id === 1) {
        return true;
      }

      return !!(
        user &&
        user.id &&
        this.discussion.author &&
        parseInt(user.id) === parseInt(this.discussion.author.id)
      );
    },
    canEditSong() {
      return canEditSong();
    },
    isAdmin() {
      return isAdmin();
    },
  },
};
</script>
