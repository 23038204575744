<template>
  <div v-if="poll.id" :id="'edit-poll-modal' + poll.id" class="create-post" uk-modal>
    <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

      <div class="text-center py-3 border-b">
        <h3 class="text-lg font-semibold">Sửa thăm dò</h3>
        <button :id="'edit-poll-modal' + poll.id + 'Close'" class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
      </div>

      <div data-simplebar style="height: 350px">
        <div class="px-5 py-2">
          <div class="keyword-input-container shadow-none border-b">
            <input v-model="poll.title" class="keyword-input with-border" placeholder="Tiêu đề cuộc thăm dò" id="title" type="text" autocomplete="off">
          </div>
          
          <div class="mt-3 border-b">
            <draggable
                v-model="poll.options"
                group="people"
                @start="drag=true"
                @end="drag=false"
                item-key="index">
              <template #item="{element, index}">
                <div class="flex items-center justify-between mb-3" style="cursor: move">
                  <div class="keyword-input-container shadow-none">
                    <input type="text" class="with-border" v-model="element.name" :placeholder="'Lựa chọn ' + (index+1)"/>
                  </div>
                  <div>
                    <a href="javascript:void(0)" v-on:click="removeOption(element.index)"
                      class="relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                      <i class="uil-trash-alt mr-1"></i> Xóa
                    </a>
                  </div>
                </div>
              </template>
            </draggable>
          </div>

          <div class="mt-3 flex justify-content-end">
            <a href="javascript:void(0)" v-on:click="addOption"
              class="mr-2 relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
              <i class="uil-plus mr-1"></i> Thêm
            </a>
          </div>
        </div>
      </div>

      <div class="flex items-center w-full justify-center border-t p-3">
        <a href="javascript:void(0)" v-on:click="updatePoll" class="bg-green-600 hover:bg-green-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
          Cập nhật</a>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import {closeModal} from "../../core/services/utils.service";
import draggable from 'vuedraggable'

export default {
  name: "EditPoll",
  components: {draggable},
  props: {
    pollProp: {
      type: Object,
      default() {
        return {
          id: "",
          title: "",
          options: []
        };
      }
    }
  },
  data() {
    return {
      poll: {
        title: "",
        options: [
          {name: "", index: 0},
          {name: "", index: 1},
          {name: "", index: 2},
          {name: "", index: 3}
        ]
      },
      deletedOptionsIDS: []
    }
  },
  methods: {
    updatePoll() {
      this.disabled = true;

      let query = `mutation($id: ID!, $title: String, $options: CRUDOptions) {
        updatePoll(input: {
          id: $id,
          title: $title,
          options: $options
        }) {
          id
        }
      }`;

      let options = {
        create: [],
        update: [],
        delete: []
      };

      if (this.poll.options) {
        for (let i = 0; i < this.poll.options.length; i++) {
          if (this.poll.options[i].id) {
            this.poll.options[i].position = i;
            let option = {
              id: this.poll.options[i].id,
              name: this.poll.options[i].name,
              position: this.poll.options[i].position
            };
            options.update.push(option);
          } else {
            let option = {
              name: this.poll.options[i].name,
              position: i
            }
            options.create.push(option);
          }
        }
      }

      if (this.deletedOptionsIDS.length) {
        options.delete = this.deletedOptionsIDS;
      }

      ApiService.graphql(query, {
        id: this.poll.id,
        title: this.poll.title,
        options: options
      })
          .then(({data}) => {
            if (data.data && data.data.updatePoll) {
              this.$toast.success("Sửa cuộc thăm dò thành công");
              this.disabled = false;
              closeModal("edit-poll-modal" + this.poll.id);
              this.$emit("pollChangeDone");
            } else {
              this.$toast.error(data.errors[0].message);
              this.disabled = false;
            }
          })
          .catch((response) => {
            this.$toast.error(response.messsage);
            this.disabled = false;
          });
    },
    addOption() {
      this.poll.options.push({name: "", index: this.poll.options.length});
    },
    removeOption(index) {
      for (var i = 0; i < this.poll.options.length; i++) {
        if (index == this.poll.options[i].index) {
          if (this.poll.options[i].id) {
            this.deletedOptionsIDS.push(this.poll.options[i].id);
          }
          break;
        }
      }
      this.poll.options = this.poll.options.filter(function (option) {
        return option.index != index;
      });
    },
  },
  mounted() {
    this.poll = this.pollProp;

    if (this.poll.options && this.poll.options.length) {
      for (var i = 0; i < this.poll.options.length; i++) {
        this.poll.options[i].index = i;
      }
    }
  }
}
</script>
