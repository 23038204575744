<template>
  <div v-if="poll" class="rounded-3xl bg-gray-300 py-4 px-4 mb-4">
    <div class="flex justify-content-between">
      <h3 class="text-lg font-semibold mb-1 border-b">{{ poll.title }}</h3>
      <div v-if="isAuthor">
        <a href="#">
          <i
            class="icon-feather-more-horizontal text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"
          ></i>
        </a>
        <div
          class="bg-white w-62 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700"
          uk-drop="mode: click;pos: bottom-right;animation: uk-animation-slide-bottom-small"
        >
          <ul class="space-y-1">
            <li><a v-if="poll.status === 'open'" v-on:click="closePoll" class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800" href="javascript:void(0)" ><i class="uil uil-lock mr-1"></i> Đóng thăm dò</a></li>
            <li><a v-if="poll.status === 'closed'" v-on:click="openPoll" class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800" href="javascript:void(0)" ><i class="uil uil-lock-open-alt mr-1"></i> Mở thăm dò</a></li>
            <li><a class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800" href="#" :uk-toggle="'target: #delete-poll-modal' + poll.id"><i class="uil uil-trash mr-1"></i> Xóa thăm dò</a></li>
            <li><a class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800" href="#" :uk-toggle="'target: #edit-poll-modal' + poll.id"><i class="uil-pen mr-1"></i> Sửa thăm dò</a></li>
          </ul>
        </div>
      </div>
    </div>
    
    <div class="mt-3 border-b py-4" v-if="poll.options && poll.options.length">
      <ul>
        <li class="mb-4" v-for="option in poll.options" :key="'option' + option.id">
          <div class="flex items-center">
            <div class="radio">
              <input :disabled="isDisable" :checked="isAnswered(option)" v-on:click="answerPoll(option.id)" :id="'radio-' + option.id" name="radio" type="radio">
              <label :for="'radio-' + option.id"><span class="radio-label"></span></label>
            </div>
            <div class="w-full">
              <div class="flex justify-content-between items-center mb-1">
                <div>{{ option.name }}: {{ option.total_answer }} ({{ option.percent }}%)</div>
                <div v-if="option.answers && option.answers.data && option.answers.data.length" class="flex items-center">
                  <router-link :uk-tooltip="'title: ' + answer.user.username" :key="'answer' + answer.id" v-for="answer in option.answers.data" :title="answer.user.username" :to="{name: 'UserDetail', params: {id: answer.user.id}}">
                    <img style="max-width: inherit;" :src="answer.user && answer.user.avatar ? answer.user.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                      class="object-cover w-6 h-6 rounded-full border-2 border-white dark:border-gray-900 -mr-2" :alt="answer.user.username">
                  </router-link>
                </div>
              </div>
              <div class="bg-gray-100 rounded-2xl h-2 w-full relative overflow-hidden">
                  <div class="bg-blue-600 h-full" :style="'width: ' + option.percent + '%'"></div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="mt-3 flex justify-content-end">
        <div v-if="myAnswer">Bạn đã chọn ý kiến: <strong>{{ myAnswer }}</strong></div>
        <div v-if="!myAnswer">Bạn chưa chọn ý kiến nào</div>
      </div>
    </div>
    <div class="mt-3">
      <div class="flex justify-content-between">
        <div class="text-sm italic">
          Cuộc thăm dò tạo bởi 
          <strong>
            <router-link :title="poll.user.username" :to="{name: 'UserDetail', params: {id: poll.user.id}}">
              {{ poll.user.username }}
            </router-link>
          </strong> <span :uk-tooltip="'title: ' + poll.created_at">{{ timeago(poll.created_at) }}</span>
        </div>
        <div v-if="poll.status === 'closed'" class="text-red-700">
          <strong>ĐÃ ĐÓNG</strong>
        </div>
      </div>
    </div>
  </div>

  <div :id="'delete-poll-modal' + poll.id" class="create-post is-story" uk-modal>
    <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

      <div class="text-center py-3 border-b">
        <h3 class="text-lg font-semibold">Xóa thăm dò</h3>
        <button :id="'delete-poll-modal' + poll.id + 'Close'" class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
      </div>

      <div class="py-4 px-4">
        Bạn có chắc muốn xóa cuộc thăm dò <strong>{{ poll.title }}</strong>
      </div>

      <div class="flex items-center w-full justify-center border-t p-3">
        <a href="javascript:void(0)" v-on:click="deletePoll" class="bg-red-600 hover:bg-red-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
          Xóa thăm dò
        </a>
      </div>
    </div>
    <edit-poll v-on:pollChangeDone="handlePollChangeDone" :pollProp="poll"/>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import {
  timeago, closeModal
} from "../../core/services/utils.service";
import EditPoll from "./EditPoll.vue";

export default {
  name: "PollCompact",
  components: {EditPoll},
  props: {
    poll: {
      type: Object,
      default() {
        return {
          id: "",
          title: "",
          options: []
        };
      }
    },
    isAuthor: Boolean
  },
  data() {
    return {
      myAnswer: "",
      myAnswers: []
    }
  },
  methods: {
    answerPoll(option_id) {
      this.disabled = true;

      let query = `mutation($poll_id: ID!, $option_ids: [ID]!) {
        answerPoll(poll_id: $poll_id, option_ids: $option_ids) {
          id
          title
        }
      }`;

      ApiService.graphql(query, {
        poll_id: this.poll.id,
        option_ids: [option_id]
      })
          .then(({data}) => {
            if (data.data && data.data.answerPoll) {
              this.$toast.success("Cảm ơn bạn đã trả lời cuộc thăm dò");
              setTimeout(function() {
                this.$emit("pollChangeDone");
              }, 1000);
              this.loadMyAnswer();
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.messsage);
          });
    },
    closePoll() {
      let query = `mutation($poll_id: ID!) {
        closePoll(poll_id: $poll_id) {
          id
        }
      }`;

      ApiService.graphql(query, {poll_id: this.poll.id})
          .then(({data}) => {
            if (data.data && data.data.closePoll) {
              this.$toast.success("Đóng thăm dò thành công");
              this.$emit("pollChangeDone");
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.messsage);
          });
    },
    deletePoll() {
      let query = `mutation($id: ID!) {
        deletePoll(id: $id) {
          id
        }
      }`;

      ApiService.graphql(query, {id: this.poll.id})
          .then(({data}) => {
            if (data.data && data.data.deletePoll) {
              this.$toast.success("Xóa thăm dò thành công");
              this.$emit("pollChangeDone");
              closeModal("delete-poll-modal" + this.poll.id);
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.messsage);
          });
    },
    openPoll() {
      let query = `mutation($poll_id: ID!) {
        openPoll(poll_id: $poll_id) {
          id
        }
      }`;

      ApiService.graphql(query, {poll_id: this.poll.id})
          .then(({data}) => {
            if (data.data && data.data.openPoll) {
              this.$toast.success("Mở thăm dò thành công");
              this.$emit("pollChangeDone");
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.messsage);
          });
    },
    loadMyAnswer() {
      this.disabled = true;

      let query = `query($poll_id: ID!) {
        myAnswer(poll_id: $poll_id) {
          data {
            id
            option {
              id
              name
            }
          }
        }
      }`;

      ApiService.graphql(query, {poll_id: this.poll.id})
          .then(({data}) => {
            if (data.data && data.data.myAnswer && data.data.myAnswer.data) {
              var option_names = [];
              for (var i = 0; i < data.data.myAnswer.data.length; i++) {
                option_names.push(data.data.myAnswer.data[i].option.name);
              }
              this.myAnswer = option_names.join("; ");
              this.myAnswers = data.data.myAnswer.data;
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.messsage);
          });
    },
    handlePollChangeDone() {
      this.$emit("pollChangeDone");
    },
    timeago(x) {
      return timeago(x);
    },
    isAnswered(option) {
      for (var i = 0; i < this.myAnswers.length; i++) {
        if (this.myAnswers[i].option.id === option.id) {
          return true;
        }
      }

      return false;
    }
  },
  mounted() {
    this.loadMyAnswer();
  },
  computed: {
    isDisable() {
      if (this.poll.status === "closed") {
        return true;
      }

      return false;
    }
  }
}
</script>
